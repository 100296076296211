import React, { Component } from "react";
import { Grid, Modal, Form, Button, Confirm, GridColumn, Icon, Header, Segment } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form';
import DateTimeForm from 'shared-components/DatePicker';
import RenderInput from 'shared-components/Input';
import RenderRadioField from 'shared-components/RadioBox';
import RenderSelect from 'shared-components/Select';
import TextArea from 'shared-components/TextArea';
import CustomTooltip from 'shared-components/Tooltip';
import CustomRendererSearch from '../custom-renderer-search';
import * as _func from './function';
import * as common from 'helpers/util-common';
import _ from "lodash";
import { minDateForInputDateFields } from 'models/common.models';
import CustomAlertModal from 'shared-components/CustomAlertModal';
import DropdownMultipleSelection from 'shared-components/MultiSelectDropDown';
import { primaryActivityModelConstant } from "../../../admin/manual-order/manual-order.model";
import ConsumerDetails from "../../../admin/manual-order/components/consumer-details";
import ConsultantDetails from "../../../admin/manual-order/components/consultant-details";
import { getMessage } from "../../../../helpers/util-common";

class AddMemberCommitteeModal extends Component {
    constructor(props) {
        super(props);
        this.searchAfterStopTypingTimer = null;
        this.state = {
            maxDate: common.formatDateToMMDDYYYY(),
            minDate: _(this.props.initialValues).get('EstablishmentDate') ? common.formatDateToMMDDYYYY(this.props.initialValues.EstablishmentDate) : common.formatDateToMMDDYYYY(minDateForInputDateFields),
            dateJoined: common.formatDateToMMDDYYYY(),
            voteStatus: 'false',
            voteDate: common.formatDateToMMDDYYYY(),
            officerAppointedDate: common.formatDateToMMYYYY(),
            classificationAssignDate: common.formatDateToMMDDYYYY(),
            searchValue: '',
            isLoading: false,
            UpdateReason: '',
            showNoResults: false,
            classificationSelectedValue: 5,
            classification: [
                { 'key': 0, 'text': 'Select', 'value': 0 }
            ],
            officerTitleSelectedValue: 0,
            officerTitle: [
                { 'key': 0, 'text': 'Select', 'value': 0 }
            ],
            noVoteReasonSelectedValue: 0,
            noVoteReason: [
                { 'key': 0, 'text': 'Select', 'value': 0 }
            ],
            renderSelectedValueFromSearchList: {},
            searchResultsData: [],
            showMainCommitteePopup: false,
            showMainCommitteeProducerPopup: false,
            popupContent: null,
            regExpSpecialCharacter: true,
            mainCommitteeMessage: '9053.text',
            isShowAdministrativeAssistantPopup: false,
            committeeDesignationValidation: '',
            isShowAdministrativeAssistantOrStaffManagerPopup: false,
            primaryActivityDropdown: [],
            masterPrimaryActivityDropdown: [],
            isOrgAssociated: false,
            primaryActivityOfMember: -1,
            isPrimaryActivityApplicable: false,
            isSaveButtonDisableForSubAndSectionCommitteePage: false,
            disableFieldsForStudentMember: false,
            isShowStudentProgramDisabled: false
        }
    }

    componentWillMount() {
        _func.setClassInstance(this);
        _func.setDropDowns();
    }
    componentDidMount() {
        common.handleAutoComplete()
    }

    handleChangeOfficerTitle = (event, value) => {
        this.setState({ officerTitleSelectedValue: value })
        const { officerTitle } = this.state;
        if (officerTitle && officerTitle.length > 0) {
            let result = officerTitle.filter((filterResult) => {
                return filterResult.value === value;
            });
            if (result && result.length > 0) {
                this.setState({ officerTitleName: _.get(result[0], 'text') })
            }
        }
    }
    render() {
        const { maxDate, dateJoined, voteStatus, voteDate, classification, classificationSelectedValue,
            officerTitle, officerTitleSelectedValue, officerAppointedDate, noVoteReason,
            noVoteReasonSelectedValue, searchValue, searchResultsData, isLoading, showNoResults,
            renderSelectedValueFromSearchList: { LastName, FirstName, MiddleName, AccountNumber, Email,
                FacilityName, membershipTypeName }, showMainCommitteePopup,
            showMainCommitteeProducerPopup, popupContent, minDate, mainCommitteeMessage, isShowAdministrativeAssistantPopup, committeeDesignationValidation, isShowAdministrativeAssistantOrStaffManagerPopup, committeeName, isPrimaryActivityApplicable, isOrgAssociated, primaryActivityOfMember, primaryActivityDropdown, isSaveButtonDisableForSubAndSectionCommitteePage, disableFieldsForStudentMember, isShowStudentProgramDisabled } = this.state;
        const { handleSubmit, closeModal, messageCodes } = this.props;
        return (
            <div data-testid="addMemberCommitteeModal">
                <Modal open='true' className={'tiny'}>
                    <Form onSubmit={handleSubmit} size='large' noValidate>
                        <Modal.Header>
                            Add Member to Committee
                            <i className={'close'} data-testid="closeBtn" onClick={closeModal}>&#x2716;</i>
                        </Modal.Header>

                        <Modal.Content scrolling>
                            <Modal.Description>
                                <Grid columns='equal'>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <CustomRendererSearch
                                                {...this.props}
                                                name={'searchMember'}
                                                label={'Search Member'}
                                                placeholder={'Search Member by Account Number, Name, Email'}
                                                required={true}
                                                isLoading={isLoading}
                                                value={searchValue}
                                                onChange={_func.handleSearh}
                                                onResultSelect={_func.handleResultSelect}
                                                results={searchResultsData}
                                                showNoResults={showNoResults}
                                                noResultsMessage={ isShowStudentProgramDisabled ? getMessage(messageCodes, '9186.text') : undefined}
                                                className="autocompleteOff"
                                            />
                                        </Grid.Column>
                                    </Grid.Row>

                                    {(AccountNumber || (LastName || FirstName || MiddleName) || Email || FacilityName || membershipTypeName) &&
                                        <Grid.Row>
                                            <Grid.Column>
                                                <div className={'memberResult clearfix'}>
                                                    <div className={'personalInfo'}>
                                                        <span className={'name'}>
                                                            {`${LastName ? LastName + ',' : ''} ${FirstName || ''} ${MiddleName && MiddleName[0].toUpperCase() || ''}`}
                                                        </span>
                                                        <span className={'otherInfo'}><em>Account Number: </em>{` ${AccountNumber || ''}`}</span>
                                                        <span className={'otherInfo'}><em>Email: </em>{` ${Email || ''}`}</span>
                                                        <span className={'otherInfo'}><em>Organization: </em>{` ${FacilityName || ''}`}</span>
                                                    </div>
                                                    <div className={'memberTypeInfo'}>

                                                        <CustomTooltip icon={<span className={'roleTypenName ellip'}>{membershipTypeName || ''}</span>} content={membershipTypeName} />

                                                    </div>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    }

                                    <Grid.Row>
                                        <Grid.Column>
                                            <Field name={'dateJoined'}
                                                component={DateTimeForm} label={'Date Joined'}
                                                defaultValue={dateJoined}
                                                showTime={false}
                                                required={true}
                                                initialDate={maxDate}
                                                maxDate={maxDate}
                                                minDate={minDate}
                                                normalize={common.dateMasking}
                                                onChange={_func.handleChangeDateJoined}
                                                readOnly={false}
                                                disabled={disableFieldsForStudentMember}
                                            />
                                        </Grid.Column>
                                        <Grid.Column><div style={{ height: '60px' }}>&nbsp;</div></Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Field name={'classification'}
                                                component={RenderSelect} label={'Classification'}
                                                required={true}
                                                value={classificationSelectedValue}
                                                onChange={_func.handleChangeClassification}
                                                options={classification}
                                                disabled={disableFieldsForStudentMember}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Field name="ClassificationDate"
                                                component={DateTimeForm} label="Classification Assigned Date"
                                                showTime={false}
                                                required={true}
                                                normalize={common.dateMasking}
                                                maxDate={maxDate}
                                                minDate={dateJoined}
                                                value={maxDate}
                                                onChange={_func.handleChangeClassificationDate}
                                                readOnly={false}
                                                disabled={disableFieldsForStudentMember}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <label className={'label'}>
                                                <i aria-hidden={true} className={'asterisk  icon'}></i>Vote
                                            </label>
                                            <div className={'radioWrap'} >
                                                {<Field name={'voteStatus'}
                                                    component={RenderRadioField}
                                                    className={'mr10'}
                                                    type={'radio'}
                                                    selectedValue={voteStatus}
                                                    onChange={_func.handleChangeVoteStatus}
                                                    isDisabled={disableFieldsForStudentMember}
                                                    options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]}
                                                />}
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column>
                                            {JSON.parse(voteStatus) ?
                                                <div style={{ height: '60px' }}>
                                                    <Field name={'voteDate'}
                                                        component={DateTimeForm}
                                                        defaultValue={voteDate}
                                                        showTime={false}
                                                        required={false}
                                                        initialDate={maxDate}
                                                        maxDate={maxDate}
                                                        minDate={dateJoined}
                                                        normalize={common.dateMasking}
                                                        onChange={_func.handleChangeVoteDate}
                                                        readOnly={false}
                                                        className={'hide'}
                                                    />
                                                </div>
                                                :
                                                <Field name={'noVoteReason'}
                                                    component={RenderSelect} label={'No Vote Reason'}
                                                    required={true}
                                                    value={noVoteReasonSelectedValue}
                                                    onChange={_func.handleChangeNoVoteReason}
                                                    options={noVoteReason}
                                                    disabled={disableFieldsForStudentMember}
                                                />
                                            }
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column>
                                            <Field name="voteDate"
                                                component={DateTimeForm} label="Vote Assigned Date"
                                                showTime={false}
                                                required={true}
                                                normalize={common.dateMasking}
                                                maxDate={maxDate}
                                                minDate={dateJoined}
                                                value={maxDate}
                                                readOnly={false}
                                                onChange={_func.handleChangeVoteDate}
                                                disabled={disableFieldsForStudentMember}
                                            />

                                            {/* <Field name={'voteDate'}
                                                component={DateTimeForm}
                                                defaultValue={voteDate}
                                                label="Vote Assigned Date"
                                                showTime={false}
                                                required={false}
                                                initialDate={maxDate}
                                                maxDate={maxDate}
                                                normalize={common.dateMasking}
                                                onChange={_func.handleChangeVoteDate}
                                                readOnly={false}
                                                className={'hide'}
                                            /> */}
                                        </Grid.Column>
                                        <Grid.Column><div style={{ height: '60px' }}>&nbsp;</div></Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Field
                                                name="OfficerTitle"
                                                label="Officer Title"
                                                value={officerTitleSelectedValue}
                                                onChange={(e, value) => this.handleChangeOfficerTitle(e, value)}
                                                options={officerTitle}
                                                component={DropdownMultipleSelection}
                                                isSearch={true}
                                                placeholder="Search by Officer Title"
                                                isMultiple={false}
                                                disabled={disableFieldsForStudentMember}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            {officerTitleSelectedValue > 0 ?
                                                <Field name="officerAppointedDate"
                                                    label="Officer Appointed Date"
                                                    required={true} placeholder='MM/YYYY'
                                                    component={RenderInput}
                                                    value={officerAppointedDate}
                                                    normalize={common.monthYearDateMask}
                                                    onChange={_func.handleChangeOfficerAppointedDate}
                                                    readOnly={false}
                                                />
                                                :
                                                ''
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                    {isPrimaryActivityApplicable &&
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Field
                                                    name="primaryActivityOfMember"
                                                    label="Primary Activity of Member "
                                                    component={RenderSelect}
                                                    required
                                                    options={primaryActivityDropdown}
                                                    onChange={_func.onChangeCommitteeDetails}
                                                    disabled={disableFieldsForStudentMember}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    }
                                    {isOrgAssociated ?
                                        <Grid.Row>
                                            <Grid.Column>
                                                {(primaryActivityOfMember === primaryActivityModelConstant.manufacturerOfProductService ||
                                                    primaryActivityOfMember === primaryActivityModelConstant.salesDistributorOfMaterialProductService ||
                                                    primaryActivityOfMember === primaryActivityModelConstant.userPurchaserOfProductService ||
                                                    primaryActivityOfMember === primaryActivityModelConstant.testingOfProductService) ? <Field
                                                    name='describeProducts'
                                                    label='Describe your Products or Services'
                                                    placeholder="Indicate the relevance of your products/services to the committee."
                                                    component={TextArea}
                                                    required={true}
                                                    maxLength={255}
                                                    isCounterVisible={true}
                                                    onChange={_func.onChangeCommonForm}
                                                /> :
                                                    (primaryActivityOfMember === primaryActivityModelConstant.governmentAgency ||
                                                        primaryActivityOfMember === primaryActivityModelConstant.academia || 
                                                        primaryActivityOfMember === primaryActivityModelConstant.other) ?
                                                        <Field
                                                            name='natureOfInterest'
                                                            label='What is the nature of your interest in the standards developed by the Committees you seek to join?'
                                                            component={TextArea}
                                                            placeholder='Click here to type'
                                                            required={true}
                                                            onChange={_func.onChangeCommonForm}
                                                            disabled={disableFieldsForStudentMember}
                                                        /> :
                                                        (primaryActivityOfMember === primaryActivityModelConstant.consumer ||
                                                            primaryActivityOfMember === primaryActivityModelConstant.consumerAdvocacyGroup) ?
                                                            <Field
                                                                name='natureOfInterest'
                                                                label={getMessage(messageCodes, '9128.text')}
                                                                component={TextArea}
                                                                placeholder='Click here to type'
                                                                required={true}
                                                                onChange={_func.onChangeCommonForm}
                                                            /> :
                                                            (primaryActivityOfMember === primaryActivityModelConstant.consultant ||
                                                                primaryActivityOfMember === primaryActivityModelConstant.consultingFirm) &&
                                                            <ConsultantDetails ref='ConsultantDetails' {...this.props} />
                                                }
                                            </Grid.Column>
                                        </Grid.Row> :
                                        <Grid.Row>
                                            <Grid.Column>
                                                {primaryActivityOfMember === primaryActivityModelConstant.consumer ?
                                                    <ConsumerDetails
                                                        ref='ConsumerDetails'
                                                        {...this.props} />
                                                    :
                                                    primaryActivityOfMember === primaryActivityModelConstant.consultant ?
                                                        <ConsultantDetails ref='ConsultantDetails' {...this.props} />
                                                        :
                                                        primaryActivityOfMember === primaryActivityModelConstant.other &&
                                                        <Field
                                                            name='natureOfInterest'
                                                            label='What is the nature of your interest in the standards developed by the Committees you seek to join?'
                                                            component={TextArea}
                                                            required
                                                            onChange={_func.onChangeCommonForm}
                                                            disabled={disableFieldsForStudentMember}
                                                        />
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                    }
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Field component={TextArea} type='text' label='Reason for update'
                                                name='UpdateReason' required={true} maxLength='200' onChange={_func.handleChangeUpdateReason}
                                                placeholder="Please enter reason for update"
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Modal.Description>
                        </Modal.Content>

                        <Modal.Actions>
                            <div>
                                <Button primary type={'submit'} disabled={!AccountNumber || isSaveButtonDisableForSubAndSectionCommitteePage} >Save</Button>
                                <Button secondary className={'cancel'} onClick={closeModal}>Cancel</Button>
                            </div>
                        </Modal.Actions>
                    </Form>
                </Modal >
                <Confirm className="confirm-box"
                    open={showMainCommitteePopup}
                    content={common.getMessage(messageCodes, '9052.text').replace("<Main Committee Designation>", popupContent)}
                    onCancel={_func.closeMainCommitteePopup}
                    onConfirm={_func.confirmMainCommittee}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />

                <Confirm className="confirm-box"
                    open={showMainCommitteeProducerPopup}
                    content={common.getMessage(messageCodes, mainCommitteeMessage).replace("<Main Committee Designation>", popupContent)}
                    onCancel={_func.closeMainCommitteeProducer}
                    onConfirm={_func.confirmMainCommitteeProducer}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />

                <Confirm className="confirm-box"
                    open={isShowAdministrativeAssistantOrStaffManagerPopup}
                    content={common.getMessage(messageCodes, '9163.text').replace('<Administrative Assistant OR Staff Manager>', this.state.officerTitleName).replace('<Main Committee Designation>', this.state.committeeName)}
                    onCancel={_func.cancelAdministrativeAssistantOrStaffManagerPopup}
                    onConfirm={_func.confirmAdministrativeAssistantOrStaffManagerPopup}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />
                {isShowAdministrativeAssistantPopup &&
                    <CustomAlertModal content={common.getMessage(messageCodes, '9524.text').replace('@OfficerTitleName', this.state.officerTitleName).replace('@committeeDesignationValidation', this.state.committeeDesignationValidation)} handleClick={_func.handleClickOk} />
                }
            </div >

        )
    }
}
export default (reduxForm({
    form: 'AddMemberCommitteeModal',
    validate: _func.validateHandler,
    onSubmit: _func.saveRecords
})(AddMemberCommitteeModal));
