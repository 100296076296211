import * as common from 'helpers/util-common';
import { get } from 'lodash';
import { AppGridIds, GridColumns, ItemsPerPage } from 'models/common.models';
import { CommitteeMemberStatusOptions, CommitteeRosterFilter, CommitteeRosterVoteOptions } from 'modules/committee-management/committee.model';
import React, { Component } from "react";
import { Button, Confirm, Icon, Loader } from 'semantic-ui-react';
import CustomDataGrid from 'shared-components/AdvancedGrid';
import RenderCheckbox from 'shared-components/CheckBox';
import CustomAlertModal from 'shared-components/CustomAlertModal';
import RenderInputField from 'shared-components/Input';
import PaginationShorthand from 'shared-components/Pager';
import RenderSelect from 'shared-components/Select';
import { decrypt } from '../../../../helpers/util-common';
import MemberCommitteeDetails from '../../../member-management/members/components/member-committee-details';
import AddMemberCommitteeModal from '../add-committee-member-modal';
import CommitteeRosterExcelExport from '../committee-roster-export-excel';
import ManageCommitteeOfficersModal from '../manage-committee-officers-modal';
import UpdateReasonModel from '../update-reason-modal';
import * as _func from './function';

class CommitteeRoster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddMemberCommitteeModal: false,
            totalItems: 100,
            totalCountForExport: 0,
            activePageNumber: 1,
            totalPages: 0,
            pageSize: 25,
            gridProp: {
                records: [],
                attributes: {},
                expandables: [],
                sortables: {},
                omitOnMenu: [],
                excelExportedColumn: [],
                emptyMessage: null,
                excelExportableFieldsCallback: null,
                displayName: {},
                requestSaveParams: {},
                allowTooltip: true
            },
            isTableHeaderReset: true,
            isShowGridMenu: false,
            isFilterChanged: false,
            isDataUpdated: false,
            activeSortColumn: { name: GridColumns.MemberDetail.AccountNumber, orderByIcon: 1 },
            isOfficerOnly: false,
            committeeUpdateModel: {},
            isUpdatePopUp: false,
            IsMainCommittee: false,
            validateProducerClassification: false,
            isEditMode: false,
            showUpdateReason: false,
            showLoader: false,
            showCommitteeOfficerModel: false,
            isOpenExcelDownloadConfirmBox: false,
            isExportExcel: false,
            exportedData: [],
            isExportingWord: false,
            isWordEmpty: false,
            officerTitlesOrderedByRulesAndException: [],
            isShowAdministrativeAssistantPopup: false,
            isShowAdministrativeAssistantOrStaffManagerPopup: false,
            officerTitleName: '',
            selectedAccountNumberFromGrid: null
        }
        this.loadScript();
    }

    loadScript = () => {
        common.loadScriptTag(['/docx/docx.js', '/docx/file-save.js', '/docx/docx-service.js'], 'head');
    }
    componentWillMount() {
        _func.setClassInstance(this);
    }
    resetDataUpdatedFlag = () => {
        this.setState({ isDataUpdated: false, isTableHeaderReset: true });
    }

    // TODO : Hide and show full screen popup.
    showHideFullPoup = (memberId, accountNumber) => {
        const { location, getMemberDetails, getMemberCommitteeInfoList } = this.props;
        let showLeftPannel = !this.state.showLeftPannel;
        let activeCommitteeId = location.pathname && decrypt(location.pathname.split('/').pop());
        this.setState({
            showLeftPannel, activeCommitteeId, showAddcommitteeMember: false, MemberId: memberId, selectedAccountNumberFromGrid: accountNumber
        });
        // TODO : Process call only on Flag : True.
        if (showLeftPannel) {
            getMemberDetails(accountNumber);
            getMemberCommitteeInfoList(accountNumber, res => {
                this.setState({
                    memberCommitteeInfo: res
                })
            });
        } else {
            _func.refreshCommitteeDetails();
        }
    }

    render() {
        const { showAddMemberCommitteeModal, activePageNumber, totalPages,
            pageSize, currentlyShowingItems, isShowGridMenu, isDataUpdated, gridProp, totalItems, activeSortColumn,
            membershipTypeSelectedValue, classificationSelectedValue, voteSelectedValue, statusSelectedValue,
            isOfficerOnly, committeeUpdateModel, isUpdatePopUp, isEditMode, showUpdateReason,
            showLoader, isOpenExcelDownloadConfirmBox, isWordEmpty, isExportExcel, exportedData, showCommitteeOfficerModel, memberCommitteeInfo, showLeftPannel, activeCommitteeId, MemberId, isShowAdministrativeAssistantPopup, isShowAdministrativeAssistantOrStaffManagerPopup, committeeName, selectedAccountNumberFromGrid } = this.state;
        const { classificationList, membershipTypeList, officerTitleList, noVoteReasonList, messageCodes,
            hasUpdateAccess, hasExportAccess, hasAddMemberToCommitteeAccess, hasEditMemberParticipationOnCommittees, hasUserPageAccess,
            initialValues: { CommitteeDesignation, StatusName, memberDetails }, PAGE_TYPE, USER_PRIVILEGE, uniqueKey } = this.props

        return (
            <div data-testid="committeeRoster" className='committe-roster'>
                {showLoader && (
                    <Loader size='small' className="small-loader">Loading</Loader>
                )}
                <div className="committeeRoster">

                    <section className="tableDataLegends">

                        <div className="legends">
                            <span className="red-border mr10"></span><span>Member is inactive</span>
                        </div>

                    </section>

                    <div className='auditFilter mb20' >

                        <div className="colWrap mr10" style={{ 'vertical-align': 'top' }}>
                            <div className="multiField" title="Search Member by Account Number, First Name, Last Name, Company">
                                <RenderInputField
                                    name='Member' type='text' label='Member'
                                    maxLength={100}
                                    required={false}

                                    placeholder={'Search Member by Account Number, First Name, Last Name, Company'}
                                    onKeyPress={_func.onEnterSearchFilterRecord}
                                />
                            </div>
                        </div>
                        <div className="colWrap mr10">
                            <label className="label">Member Type</label>
                            <RenderSelect name="MemberType" value={membershipTypeSelectedValue} placeholder="All" onChange={(e, value) => _func.onChangeFilter(value, CommitteeRosterFilter.MemberType)} options={membershipTypeList} />
                        </div>
                        <div className="colWrap mr10">
                            <label className="label">Classification</label>

                            <RenderSelect name="Classification" value={classificationSelectedValue} placeholder="All" onChange={(e, value) => _func.onChangeFilter(value, CommitteeRosterFilter.Classification)} options={classificationList} />
                        </div>
                        <div className="colWrap mr10">
                            <label className="label">Vote</label>
                            <RenderSelect name="Vote" placeholder="All" value={voteSelectedValue} onChange={(e, value) => _func.onChangeFilter(value, CommitteeRosterFilter.Vote)} options={CommitteeRosterVoteOptions} />
                        </div>
                        <div className="colWrap mr10">
                            <label className="label">Status</label>
                            <RenderSelect name="Status" placeholder="All" value={statusSelectedValue} onChange={(e, value) => _func.onChangeFilter(value, CommitteeRosterFilter.Status)} options={CommitteeMemberStatusOptions} />
                        </div>
                        <div className="colWrap">
                            <label className="label">&nbsp;</label>
                            <RenderCheckbox setLabeltitleProp="Show Officers Only" onClick={_func.OnChangeOfficerOnly} IsChecked={isOfficerOnly} IsEditable={true} />
                        </div>
                        <div className="auditAction">
                            <a className="resetBtn vMiddle ml10" href="#" data-testid="resetBtn" onClick={_func.onChangeFilter} ><Icon name="redo" /></a>
                            <a className="updateBtn vMiddle ml10" href="#" data-testid="updateBtn" onClick={_func.searchFilterRecord} ><Icon name="check" /></a>
                        </div>
                    </div>

                    <div className="mciActions clearfix">
                        {(hasAddMemberToCommitteeAccess && StatusName.toLowerCase() === 'active') && <Button secondary onClick={_func.openAddMemberToCommitteeModal}>
                            <Icon name="plus" />Add Member to Committee</Button>
                        }
                        {(hasUpdateAccess && StatusName.toLowerCase() === 'active') && <Button secondary className="ml10" onClick={_func.showHideCommitteeOfficerModel}><Icon name="setting" /> Committee Officers</Button>}
                        {hasExportAccess &&
                            <div className="display-inline-block floatRight">
                                <span className="label2  mr10 vMiddle display-inline-block">Export as:</span>
                                <Button secondary onClick={_func.excelExport}><Icon name="file excel" className="mr5" />Excel</Button>
                                <Button className="ui button secondary " onClick={_func.wordExport}><i className="icon file word mr5"></i>Word</Button>
                            </div>
                        }
                    </div>
                    <div>
                        {gridProp.attributes && Object.entries(gridProp.attributes).length > 0 && (
                            <CustomDataGrid {...this.props} isShowGridMenuEvent={isShowGridMenu} gridId={AppGridIds.CommitteeRosterGrid}
                                detailsPageUrl={uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW)} detailsPageUrl2={uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW)}
                                isDataUpdated={isDataUpdated} resetDataUpdatedFlag={this.resetDataUpdatedFlag}
                                gridSetting={gridProp} onChangeSort={_func.onChangeSortHandler}
                                saveGridPerferenceClickAction={_func.saveGridPreferenceClickAction} activeSortColumn={activeSortColumn} selectedCommitteeId={this.props.initialValues.CommitteeId}
                                masterData={{ classificationList: classificationList.filter(item => item.text !== 'All'), officerTitleList: officerTitleList.map(item => { if (item.text === 'All') { item.text = 'Select' } return item }), noVoteReasonList: noVoteReasonList.map(item => { if (item.text === 'All') { item.text = '' } return item }) }}
                                modelEntity={committeeUpdateModel} onEditChange={_func.onEditChange} OnUpdateRecored={_func.OnUpdateRecored} isEditMode={isEditMode}
                                fixedHeaderOnTop={670}
                                showHideFullPoup={this.showHideFullPoup}
                                isTableOnFullWindow={true} hasEditMemberParticipationOnCommittees={hasEditMemberParticipationOnCommittees && this.props.initialValues.StatusName.toLowerCase() === 'active'} />
                        )}
                    </div>
                    {totalItems > 0 && (
                        <div className="pagerWrap" data-testid="pagerWrap">
                            <div className="pager">
                                <PaginationShorthand defaultActivePage={activePageNumber} totalPages={totalPages}
                                    onPageChange={_func.onPageChangeHandler} />
                                <div>
                                </div>
                            </div>
                            <div className="itemPerPage">
                                <RenderSelect
                                    name="itemsPerPage"
                                    value={pageSize}
                                    onChange={_func.onChangeNumberOfItemsPerPage}
                                    options={ItemsPerPage}
                                />
                                <span className="itemsPerPage">items per page</span>
                            </div>

                            <div className="totalPage">{currentlyShowingItems}</div>

                        </div>
                    )}
                    {showAddMemberCommitteeModal && <AddMemberCommitteeModal {...this.props} refreshCommitteeDetails={_func.refreshCommitteeDetails} closeModal={_func.closeAddMemberToCommitteeModal} />}
                    {(showUpdateReason && isEditMode) && <UpdateReasonModel {...this.props} onSubmitCall={_func.OnUpdateRecored} onClose={_func.popUpCancel} />}
                    {showCommitteeOfficerModel && <ManageCommitteeOfficersModal {...this.props} showHide={_func.showHideCommitteeOfficerModel} hasUpdateAccess={hasUpdateAccess} />}

                    {isExportExcel &&
                        <CommitteeRosterExcelExport data={exportedData} CommitteeDesignation={CommitteeDesignation} />
                    }
                    <Confirm className="confirm-box"
                        open={isUpdatePopUp || isOpenExcelDownloadConfirmBox || isWordEmpty}
                        content={common.getMessage(messageCodes, (isUpdatePopUp && '9044.text') || ((isOpenExcelDownloadConfirmBox || isWordEmpty) && '9014.text'))}
                        onCancel={(isUpdatePopUp && _func.updatePopupCancel) || (isOpenExcelDownloadConfirmBox && _func.emptyExportDataCancelHandler) || (isWordEmpty && _func.emptyWordCancelHandler)}
                        onConfirm={(isUpdatePopUp && _func.popUpConfirm) || (isOpenExcelDownloadConfirmBox && _func.emptyExportDataConfirmHandler) || (isWordEmpty && _func.emptyWordConfirmHandler)}
                        size="tiny"
                        cancelButton="No"
                        confirmButton="Yes"
                    />
                </div >
                <div className={showLeftPannel ? 'fullPagePopup active' : 'fullPagePopup'}>
                    {showLeftPannel && memberCommitteeInfo && memberDetails && get(memberDetails, 'MemberId') &&
                        <MemberCommitteeDetails
                            memberCommitteeInfo={memberCommitteeInfo}
                            showLeftPannel={showLeftPannel}
                            activeCommitteeId={activeCommitteeId}
                            showHide={this.showHideFullPoup}
                            MemberTypeId={get(memberDetails, 'MemberTypeId')}
                            AccountNumber={selectedAccountNumberFromGrid}
                            MemberId={MemberId}
                            hasEditMemberParticipationOnCommittees={hasEditMemberParticipationOnCommittees}
                            userAccess={{ hasUserPageAccess }}
                            {...this.props}
                        />
                    }
                </div>
                <Confirm className="confirm-box"
                    open={isShowAdministrativeAssistantOrStaffManagerPopup}
                    content={common.getMessage(messageCodes, '9163.text').replace('<Administrative Assistant OR Staff Manager>', this.state.NewOfficerTitleName).replace('<Main Committee Designation>', committeeName)}
                    onCancel={_func.cancelAdministrativeAssistantOrStaffManagerPopup}
                    onConfirm={_func.confirmAdministrativeAssistantOrStaffManagerPopup}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />
                {isShowAdministrativeAssistantPopup &&
                    <CustomAlertModal content={common.getMessage(messageCodes, '9522.text').replace('@OfficerTitleName', this.state.NewOfficerTitleName)} handleClick={_func.handleClickOk} />
                }
            </div>
        );
    }
}
export default CommitteeRoster;
